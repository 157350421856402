<template>
  <div class="f2gNoteForm">
    <div class="f2gNoteFormInputContainer my-2">
      <vue-editor
        v-model="note.text"
        :editorOptions="editorOptions"
        :placeholder="$t('common.addNoteHelp')"
        @keydown.enter="createTag()"
      />
    </div>
    <v-layout align-end justify-end>
      <F2gButton
        class="mr-2"
        customClass="error"
        small
        outlined
        :text="$t('common.cancel')"
        @click="emitCancelEvent()"
      />
      <F2gButton
        class="mr-0"
        customClass="success"
        small
        :text="$t('common.save')"
        @click="emitSaveEvent()"
      />
    </v-layout>
  </div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";
import Emoji from "quill-emoji/dist/quill-emoji";

Quill.register(
  {
    "formats/emoji": Emoji.EmojiBlot,
    "modules/short_name_emoji": Emoji.ShortNameEmoji,
    "modules/toolbar_emoji": Emoji.ToolbarEmoji,
    "modules/textarea_emoji": Emoji.TextAreaEmoji,
  },
  true
);
import "quill-emoji/dist/quill-emoji.css";

const editorOptions = {
  modules: {
    toolbar: {
      container: [
        [{ size: ["small", false, "large"] }],
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        [{ header: 1 }, { header: 2 }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ direction: "rtl" }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ["clean"],
        ["link", "image", "video"],
        ["emoji"],
      ],
      handlers: {
        emoji: function () {},
      },
    },
    toolbar_emoji: true,
    short_name_emoji: true,
    textarea_emoji: true,
  },
};

export default {
  name: "F2gNoteForm",
  props: {
    clearNoteForm: {
      default: false,
      required: false,
      type: Boolean,
    },
    text: {
      default: "",
      required: false,
      type: String,
    },
    textSize: {
      default: 16,
      required: false,
      type: Number,
    },
  },
  components: {
    VueEditor,
  },
  data() {
    return {
      editorOptions,
      note: {
        text: "",
      },
    };
  },
  created() {
    this.initNote();
  },
  watch: {
    clearNoteForm() {
      if (this.clearNoteForm) {
        this.initNote();
        this.sendData();
      }
    },
    note: {
      deep: true,
      immediate: true,
      handler() {
        this.sendData();
      },
    },
  },
  methods: {
    emitCancelEvent() {
      this.$emit("cancel", this.note);
    },
    emitSaveEvent() {
      this.$emit("save", this.note);
    },
    initNote() {
      this.note.text = this.text;
    },
    sendData() {
      this.$emit("input", this.note);
    },
  },
};
</script>

<style scoped>
.f2gNoteForm {
  font-size: 10px;
}
.f2gNoteFormInputContainer {
  border: 1px solid grey;
  border-radius: 4px;
  font-size: 8px;
}
</style>
